<template>
  <!-- eslint-disable max-len -->
  <div class="best-product__content--item">
    <div class="infomation__favorite" @click="changeFavoriteStatus(template.id)">
      <img src="../../assets/images/heart-inactive.svg" :class="isFavorite(template.id) ? 'd-none' : ''">
      <img src="../../assets/images/heart-active.svg" :class="!isFavorite(template.id) ? 'd-none' : ''">
    </div>
    <div data-bs-toggle="modal" data-bs-target="#modalPreview">
      <div class="infomation__image">
        <img :src="template.thumbnail" alt="" @load="loadImage" v-show="isLoadedImage"/>
        <PuSkeleton v-show="!isLoadedImage"></PuSkeleton>
      </div>
      <div class="infomation__content">
        <h3 class="mb-0 infomation__content--title template--name nowrap">
          {{ template.productName }}
        </h3>
        <div class="infomation__content--views d-flex align-itesm-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 24px; height: 24px;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1">
            <path d="M347.6 699c1.6 0 3.2-0.2 4.8-0.4L486.9 675c1.6-0.3 3.1-1 4.2-2.2l339.1-339.1c3.1-3.1 3.1-8.2 0-11.3l-133-133c-1.5-1.5-3.5-2.3-5.7-2.3s-4.2 0.8-5.7 2.3l-339 339c-1.2 1.2-1.9 2.6-2.2 4.2L321 667.2c-1.5 8.9 1.2 17.5 7.5 23.8 5.3 5.2 11.9 8 19.1 8z m53.9-139.5l290.2-290.1 58.6 58.6-290.2 290.1-71.1 12.6 12.5-71.2zM739 827H284c-19.9 0-36-16.1-36-36s16.1-36 36-36h455c19.9 0 36 16.1 36 36s-16.1 36-36 36z"/>
          </svg>
          <span class="infomation__content--description ms-1 mb-0 d-flex align-items-center">{{ template.usedNumber }}</span>
        </div>
        <!-- <p class="infomation__content--description">
          <span>{{ template.productDescription }}</span>
        </p>
        <div class="d-flex align-items-center justify-content-between">
          <p class="mb-0 infomation__content--description template--color">
            Color: <span>{{ colorName(template.productColor) }}</span>
          </p>
          <span class="infomation__content--price mb-0 template--price">${{ getPrice(template) }}</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { getColorNameByValue } from '@/helpers/color.helper';

export default {
  props: {
    template: {
      type: Object,
    },
  },
  name: 'TemplateCard',
  data() {
    return {
      isLoadedImage: false,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      currentUser: 'authentication/currentUser',
      masterTypesList: 'masterTypes/masterTypeList',
    }),
    ...mapState({
      wishlistTemplateIds: (state) => state.wishlist.wishlistTemplateIds,
    }),
  },
  methods: {
    ...mapActions({
      addWishlist: 'wishlist/addWishlist',
      removeWishlist: 'wishlist/removeWishlist',
    }),
    loadImage() {
      this.isLoadedImage = true;
    },
    changeFavoriteStatus(templateId) {
      if (this.currentUser.id) {
        if (this.isFavorite(templateId)) {
          this.removeWishlist({ productId: templateId });
        } else {
          this.addWishlist({ productId: templateId });
        }
      } else {
        this.$router.push('Login');
      }
    },
    isFavorite(id) {
      return this.wishlistTemplateIds.includes(id);
    },
    getPrice(template) {
      const size = template.productSize.toLowerCase();
      const sizePrices = this.masterTypesList.find((type) => type.id === template.typeId).meta['size-price'];
      return sizePrices.filter((item) => item.size.toLowerCase() === size)[0].price;
    },
    colorName(value) {
      return getColorNameByValue(value);
    },
  },
};
</script>

<style scoped>
.template--name {
  font-size: 14px !important;
}
.template--price {
  font-size: 22px !important;
}
.template--color {
  text-transform: capitalize;
}
</style>
