/* eslint-disable */
<template>
  <!-- eslint-disable max-len -->
  <fragment>
    <!-- start template.html-->
    <section class="content-wrapper template-content">
      <nav class="breadcrumb-show">
        <div class="container">
          <a href="javascript:(0)" class="breadcrumb-show__item" aria-current="page"
            >Collection</a
          >
          <p class="breadcrumb-show__description">
            Print your desired look on ready-made shirts with various sizes and
            colors.
          </p>
        </div>
      </nav>
      <div class="best-product pt-0">
        <div class="container">
          <div class="list-category">
                <div class="list-category__category">
                  <div
                    v-for="(type, index) in collectionTabs"
                    :key="index"
                  >
                    <div
                      class="list-category__category--item"
                      :class="activeTab == type ? 'active-tab' : ''"
                      :style="{ 'background-color': getBgColor(index) }"
                      :data-value="type"
                    >
                      <div class="name-category cursor-pointer">{{ capitalize(type) }}</div>
                    </div>
                  </div>
                </div>
                <button class="btn position-absolute p-0 ct-slick ct-pre-slick">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="1">
                      <g filter="url(#filter0_d_850_103)">
                        <circle
                          r="20"
                          transform="matrix(-1 0 0 1 37 37)"
                          fill="white"
                        />
                      </g>
                      <path
                        d="M39.0002 28.5711L31.2784 36.2929C30.8879 36.6834 30.8879 37.3166 31.2784 37.7071L39.0002 45.4289"
                        stroke="#656782"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_850_103"
                        x="0"
                        y="0"
                        width="80"
                        height="80"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="3" dy="3" />
                        <feGaussianBlur stdDeviation="10" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.821285 0 0 0 0 0.841373 0 0 0 0 0.908333 0 0 0 0.3 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_850_103"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_850_103"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </button>
                <button class="btn position-absolute p-0 ct-slick ct-next-slick">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_850_131)">
                      <circle cx="37" cy="37" r="20" fill="white" />
                    </g>
                    <path
                      d="M34.9998 28.5711L42.7216 36.2929C43.1121 36.6834 43.1121 37.3166 42.7216 37.7071L34.9998 45.4289"
                      stroke="#656782"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                    <defs>
                      <filter
                        id="filter0_d_850_131"
                        x="0"
                        y="0"
                        width="80"
                        height="80"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="3" dy="3" />
                        <feGaussianBlur stdDeviation="10" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.821285 0 0 0 0 0.841373 0 0 0 0 0.908333 0 0 0 0.3 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_850_131"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_850_131"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </button>
              </div>
          <div class="best-product__content mt-3">
            <div class="row align-items-center mb-3">
              <div class="col-10 col-md-7 col-lg-8">
                <h3 class="template-filter__title">{{ capitalize(activeTab) }} collection</h3>
              </div>
              <div class="col-2 col-md-5 col-lg-4 d-none d-md-block">
                <div
                  class="best-product__filter d-none d-md-flex align-items-center justify-content-end flex-grow-1"
                >
                  <div class="best-product__filter--search">
                    <div class="search-form me-3">
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                        v-model="searchTemplateText"
                      />
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.5"
                          d="M17.5 17.5L13.75 13.75"
                          stroke="#656782"
                          stroke-width="1.25"
                          stroke-linecap="round"
                        ></path>
                        <circle
                          cx="9.375"
                          cy="9.375"
                          r="6.25"
                          stroke="#656782"
                          stroke-width="1.25"
                        ></circle>
                      </svg>
                    </div>
                  </div>
                  <select class="form-control select-custom w-auto" id="select-type-shirt" v-model="searchType">
                    <option value="tshirt" selected="selected">T-shirt</option>
                    <option value="sweatshirt">Sweatshirt</option>
                    <option value="hoodie">Hoodie</option>
                    <option value="tanktop">Tank Top</option>
                    <option value="tshirtkid">T-shirt Kid</option>
                  </select>
                </div>
              </div>

              <div
                class="col-2 best-product__filter d-flex justify-content-end flex-grow-1 d-md-none"
              >
                <button class="btn p-0 open-filter">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      d="M17.5 17.5L13.75 13.75"
                      stroke="#656782"
                      stroke-width="1.25"
                      stroke-linecap="round"
                    />
                    <circle
                      cx="9.375"
                      cy="9.375"
                      r="6.25"
                      stroke="#656782"
                      stroke-width="1.25"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="best-product__filter-mobile">
              <button class="btn p-1 mb-3 close-filter" @click="hideSearchMobile()">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 5.3999L9.20526 14.1946C8.76047 14.6394 8.76047 15.3606 9.20526 15.8054L18 24.6001"
                    stroke="#656782"
                    stroke-width="1.42368"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
              <div class="search-form row">
                <div class="col-9 p-0 pe-1">
                  <input
                    class="form-control"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    v-model="searchTemplateText"
                  />
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      d="M17.5 17.5L13.75 13.75"
                      stroke="#656782"
                      stroke-width="1.25"
                      stroke-linecap="round"
                    ></path>
                    <circle
                      cx="9.375"
                      cy="9.375"
                      r="6.25"
                      stroke="#656782"
                      stroke-width="1.25"
                    ></circle>
                  </svg>
                </div>
                <div class="col-3 p-0">
                  <select class="form-control select-custom p-1" id="select-type-shirt" v-model="searchType">
                    <option value="tshirt" selected="selected">T-shirt</option>
                    <option value="sweatshirt">Sweatshirt</option>
                    <option value="hoodie">Hoodie</option>
                    <option value="tanktop">Tank Top</option>
                    <option value="tshirtkid">T-shirt Kid</option>
                  </select>
                </div>
              </div>

              <div class="result-filter">
                <div class="title-result-search" v-if="searchTemplateText">
                  {{ totalResults }} results for “{{ searchTemplateText }}”
                </div>
                <div class="row">
                  <div class="col-6 col-md-4 col-lg-3" v-for="template in paginatedTemplates" :key="template.id" @click="updateDataModal(template)">
                    <template-card :template="template"/>
                  </div>
                  <button class="btn btn-primary-2 d-flex m-auto w-auto" @click="loadMore" v-if="currentPage * maxPerPage < filteredTemplates.length">Load more</button>
                </div>
              </div>
            </div>
            <div class="title-result-search d-md-block d-none" v-if="searchTemplateText">
              {{ totalResults }} results for “{{ searchTemplateText }}”
            </div>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="shirt-tab-pane"
                role="tabpanel"
                aria-labelledby="shirt-tab"
                tabindex="0"
              >
                <div class="row">
                  <div class="col-6 col-md-4 col-lg-3" v-for="template in paginatedTemplates" :key="template.id" @click="updateDataModal(template)">
                    <template-card :template="template"/>
                  </div>
                </div>
                <button class="btn btn-primary-2 d-flex m-auto" @click="loadMore" v-if="currentPage * maxPerPage < filteredTemplates.length">Load more</button>
                <!-- <div class="pagination__content pt-lg-5 pt-4" style="text-align: center;">
                  <jw-pagination :items="filteredTemplates" @changePage="onChangePage" :page-size="pageSize" :labels="customLabels"></jw-pagination>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div
      class="modal fade preview-modal"
      id="modalPreview"
      tabindex="-1"
      aria-labelledby="modalPreviewLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-fix-bottom">
        <div class="modal-content">
          <div class="d-flex justify-content-between">
          <div class="p-4 pb-2 d-none d-md-block">
              <h3 class="modal-title text-ellipse-1 text-start">{{ selectedTemplateName }}</h3>
              <p class="modal-author">by {{ author }}</p>
          </div>
            <button
              type="button"
              class="btn pt-3 d-flex"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="4"
                  y="3"
                  width="25"
                  height="1.5"
                  rx="0.75"
                  transform="rotate(45 4 3)"
                  fill="#656782"
                />
                <rect
                  x="2.93945"
                  y="20.6777"
                  width="25"
                  height="1.5"
                  rx="0.75"
                  transform="rotate(-45 2.93945 20.6777)"
                  fill="#656782"
                />
              </svg>
            </button>
          </div>
          <div class="modal-body p-4 pt-0">
            <div class="modal-main-content">
              <div class="modal-main-content__item image-fix">
                <img :src="thumbnailDetail" alt="" class="w-100 mw-100" />
              </div>
              <button class="btn w-100 btn-primary-2 mt-4" @click="editDesign">Edit Design</button>
              <!-- <button class="btn w-100 btn-primary mt-2" v-if="currentUser.id" >Add to cart</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
<!-- end template.html-->
  </fragment>
</template>
<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';
import TemplateCard from '@/components/templates/TemplateCard.vue';
import { fabric } from "@/assets/js/fabric.js";
import { BG_COLORS } from '@/constants/colors.js';

const customLabels = {
    first: '',
    last: '',
    previous: '',
    next: ''
};
export default {
  components: {
    TemplateCard,
  },
  data() {
    return {
      templatesOrigin: [],
      viewedTemplates: [],
      collectionTabs: [],
      defaultColectionTabs: ['Trending'],
      activeTab: 'Trending',
      searchTemplateText: '',
      searchType: 'tshirt',
      pageOfItems: [],
      customLabels,
      canvasFront: null,
      canvasBack: null,
      canvas: null,
      masterTypes: [],
      productId: null,
      drawWidthArea: 0,
      drawHeightArea: 0,
      thumbnailDetail: null,
      selectedTemplateName: '',
      author: '',
      currentPage: 1,
      maxPerPage: 12,
      // pageSize: 12
    }
  },
  created() {
  },
  async mounted() {
    if (!!this.currentUser.id) this.getWishlists()
    this.getMasterTypes();
    await this.getCollectionByCategory();
    await this.getTemplates();
    // await this.getPopularTemplates();
    await this.getTrendingTemplates();
    await this.setCollectionTabs();
    this.templatesOrigin = this.templates;
    this.setTemplates(this.activeTab);
    /* eslint-disable */
    $('#pick-color').click(function () {
      $('.content-sub.color').addClass('active');
    });
    $('#basic-item').click(function () {
      $('.content-sub.basic').addClass('active');
    });
    $('#sticker-item').click(function () {
      $('.content-sub.sticker').addClass('active');
    });
    $('#close-tool-edit').click(function () {
      $('.tab-pane.fade').removeClass('active');
    });
    $('#close-tool-edit').click(function () {
      $('.tab-pane.fade').removeClass('show');
    });
    $('.open-filter').click(function () {
      $('.best-product__filter-mobile ').addClass('show');
    });
    $('.navbar-toggler').click(function () {
      $('body').addClass('menu-is-open');
    });

    $('.btn-close-collapse').click(function () {
      $('body').removeClass('menu-is-open');
      $('.navbar-collapse').removeClass('show');
    });
    $('.select-view__icon').click(function () {
      $('.select-icon').toggleClass('active');
    });
    $('.select-view__icon-back').click(function () {
      $('.select-icon').toggleClass('active');
    });
    $('.action-tool__content').click(function () {
      $('.modal-open-toggle').addClass('show');
    });
    $('.modal-open-toggle').click(function () {
      $('.modal-open-toggle').removeClass('show');
    });
    $('.dropdown-item').click(function () {
      $('.modal-open-toggle').removeClass('show');
    });
    // $('.modal-main-content__item').slick({
    //   dots: true,
    //   infinite: true,
    //   speed: 300,
    //   slidesToShow: 1,
    //   centerMode: true,
    //   variableWidth: true,
    // });
    // $('.slider__image').slick({
    //   dots: true,
    //   infinite: true,
    //   speed: 300,
    //   slidesToShow: 1,
    // });

    // var init = {
    //   rows: 2,
    //   dots: false,
    //   arrows: false,
    //   infinite: true,
    //   speed: 300,
    //   slidesToShow: 3,
    //   slidesToScroll: 3,
    //   variableWidth: true,
    // };

    // $(function () {
    //   var win = $(window);
    //   var slider = $('.image-panel__item');

    //   win.on('load resize', function () {
    //     if (win.width() < 768) {
    //       slider.not('.slick-initialized').slick(init);
    //     } else if (slider.hasClass('slick-initialized')) {
    //       slider.slick('unslick');
    //     }
    //   });
    // });

    // var initCategory = {
    //   rows: 2,
    //   dots: false,
    //   arrows: false,
    //   infinite: true,
    //   speed: 300,
    //   slidesToShow: 2,
    //   slidesToScroll: 2,
    // };
    // var initCategoryMobile = {
    //   rows: 3,
    //   dots: false,
    //   arrows: false,
    //   infinite: true,
    //   speed: 300,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    // };

    // $(function () {
    //   var win = $(window);
    //   var slider = $('.category-style__form');
    //   win.on('load resize', function () {
    //     if (win.width() < 768) {
    //       slider.not('.slick-initialized').slick(initCategoryMobile);
    //     } else if (slider.hasClass('slick-initialized')) {
    //       slider.slick('unslick');
    //     }
    //   });
    //   win.on('load resize', function () {
    //     if (win.width() < 1200) {
    //       slider.not('.slick-initialized').slick(initCategory);
    //     } else if (slider.hasClass('slick-initialized')) {
    //       slider.slick('unslick');
    //     }
    //   });
    // });

    $(".list-category__category").slick({
      dots: false,
      infinite: true,
      arrows: false,
      speed: 300,
      slidesToShow: 8,
      variableWidth: true,
      slidesToScroll: 4,
      loop: true,
      rewind: true,
    });
    $(".ct-pre-slick").click(function () {
      $(".list-category__category").slick("slickPrev");
    });
    $(".ct-next-slick").click(function () {
      $(".list-category__category").slick("slickNext");
    });
    $(".list-category__category--item").on('click', (e) => {
      const type = $(e.currentTarget).data('value');
      this.setTemplates(type);
    });

    let selectTypesShirt = $('#select-type-shirt').select2({
      minimumResultsForSearch: -1,
    });
    selectTypesShirt.on('select2:select', function (e) {
      let event = new Event('change');
      e.target.dispatchEvent(event);
    });
    const element = document.getElementById('tcanvas');
    const canvas = new fabric.Canvas(element, {
      evented:true,
      selectable: false,
    });
    this.canvas = canvas;
    this.canvas.deactivateAll();
  },
  computed: {
    ...mapGetters({
      masterTypesList: 'masterTypes/masterTypeList',
      // designSelected: 'design/designSelected',
      collectionList: 'collections/collectionList',
      currentUser: 'authentication/currentUser',
      templates: 'template/templates',
      // popularTemplates: 'template/popularTemplates',
      trendingTemplates: 'template/trendingTemplates',
    }),
    pageSize() {
      return 12;
    },
    filteredTemplates() {
      return this.viewedTemplates
        .filter(
          (template) => template.type === this.searchType.toLowerCase()
          && (
            template.productName.toLowerCase().includes(this.searchTemplateText.toLowerCase())
            || template.collections.split(',').includes(this.searchTemplateText.toLowerCase())
            || template.thumbnail.toLowerCase().includes(this.searchTemplateText.toLowerCase())
          )
        );
    },
    totalResults() {
      return Object.keys(this.filteredTemplates).length;
    },
    pageCount() {
      return Math.ceil(this.totalResults / this.maxPerPage);
    },
    pageOffest() {
      return this.maxPerPage * this.currentPage;
    },
   paginatedTemplates() {
      return this.filteredTemplates.slice(0, this.currentPage * this.maxPerPage);
    }
  },
  methods: {
    ...mapActions({
      getMasterTypes: 'masterTypes/getMasterTypes',
      getCollectionByCategory: 'collections/getCollectionByCategory',
      getWishlists: 'wishlist/getWishlists',
      getTemplates: 'template/getTemplates',
      // getPopularTemplates: 'template/getPopularTemplates',
      getTrendingTemplates: 'template/getTrendingTemplates',
    }),
    hideModal() {
      $("#modalPreview").modal("toggle");
    },
    hideSearchMobile() {
      this.searchTemplateText = '';
      $('.best-product__filter-mobile ').removeClass('show');
    },
  /* eslint-enable */
    updateDataModal(template) {
      this.productId = template.id;
      this.thumbnailDetail = template.thumbnail;
      this.selectedTemplateName = template.productName;
      this.author = template.author;
    },
    loadMore() {
      this.currentPage += 1;
    },
    editDesign() {
      if (this.productId) {
        this.hideModal();
        this.$router.push(`/design/${this.productId}`);
      }
    },
    capitalize(str) {
      return str[0].toUpperCase() + str.slice(1);
    },
    setTemplates(tabName) {
      this.activeTab = tabName;
      this.currentPage = 1;
      switch (tabName) {
        // case 'Popular':
        //   this.viewedTemplates = [...this.popularTemplates];
        //   break;
        case 'Trending':
          this.viewedTemplates = [...this.trendingTemplates];
          break;
        default:
          this.viewedTemplates = this.templatesOrigin.filter((temp) => temp.collections.split(',').includes(this.activeTab));
      }
    },
    setCollectionTabs() {
      this.collectionTabs = this.collectionList.map((collection) => collection.name);
      this.collectionTabs = this.defaultColectionTabs.concat(this.collectionTabs);
    },
    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems;
    },
    getBgColor(index) {
      const size = BG_COLORS.length;
      return BG_COLORS[index % size];
    },
  },
};
</script>

<style>
  #drawingArea {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 254.4px;
    height: 378px;
    transform: translate(-50%, -50%);
  }
</style>
